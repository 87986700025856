import ChevronButton from 'components/ChevronButton'
import { screens } from 'lib/constants'
import useMedia from 'use-media'
import cx from 'classnames'
import Image from 'components/Image'
import useEmblaCarousel from 'embla-carousel-react'
import { useCallback, useEffect, useState } from 'react'
import getColorStyle from 'lib/getColorStyle'
import AutoScroll from 'embla-carousel-auto-scroll'

const CertificationModule = ({ module }) => {
  const { headline, mobileHeadlineAlignment, mobileCaptionAlignment, icons, backgroundColor, isAutoscroll  } = module

  const isScreenLarge = useMedia({ minWidth: screens.l })
  const isScreenSmall = useMedia({ maxWidth: screens.s })
  const isScreenExtraLarge = useMedia({ minWidth: screens.xxl })
  const decodedBackgroundColor = getColorStyle(backgroundColor) ?? '#f5f9ff'
  const enableAutoscroll = isAutoscroll && isScreenSmall;
  const plugins = enableAutoscroll ? [AutoScroll({ speed: 1, startDelay: 100 })] : []
  const [index, setIndex] = useState(0)
  const isCarouselEnabled = isScreenLarge ? icons?.length > 10 : icons?.length > 3
  const [ref, embla] = useEmblaCarousel({
    loop: false,
    align: 'start',
    dragFree: true,
  }, plugins)
  const scrollPrev = useCallback(() => embla?.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla?.scrollNext(), [embla])
  const canScrollNext = useCallback(() => embla?.canScrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setIndex(embla.selectedScrollSnap())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on('select', onSelect)

    if (plugins.length && isScreenSmall) {
      embla?.reInit({
        loop: true,
        align: 'start',
        dragFree: true,
      }, plugins)
      const autoScroll = embla?.plugins()?.autoScroll
      const handlePointerUp = () => {
        if (!autoScroll?.isPlaying()) {
          autoScroll?.play()
        }
      }
      embla?.on('pointerUp', handlePointerUp)
      return () => {
        embla?.off('pointerUp', handlePointerUp)
      }
    } else {
      embla?.reInit({
        loop: false,
        align: 'start',
        dragFree: true,
      }, plugins)
    }
  }, [embla, onSelect, isScreenSmall])

  return (
    <div style={{ background: decodedBackgroundColor }} className="py-25 l:py-50">
      <div
        style={{ maxWidth: isScreenSmall ? '320px' : '100%' }}
        className={cx(
          'flex items-center pl-25 pr-5 justify-center',
          'text-24 l:text-36 xl:text-40 font-medium leading-130 mb-30',
          {
            'justify-center': isScreenSmall && mobileHeadlineAlignment === 'center',
            'justify-start': isScreenSmall && mobileHeadlineAlignment === 'left',
            'justify-end': isScreenSmall && mobileHeadlineAlignment === 'right',
          }
        )}
      >
        {headline}
      </div>

      <div className="relative">
        <div ref={isCarouselEnabled ? ref : null} className="px-20 l:px-50 overflow-hidden">
          <div
            className={cx('flex', {
              'justify-center': !isCarouselEnabled,
              'gap-5 2xs:gap-20 xs:gap-25': isCarouselEnabled,
            })}
          >
            {icons &&
              icons.length > 0 &&
              icons.map((icon, index) => {
                return (
                  <div className="flex flex-col justify-start items-center w-full" key={icon._key}>
                    <div
                      style={{
                        width: isScreenExtraLarge ? '130px' : isScreenLarge ? '100px' : '80px',
                      }}
                    >
                      <Image
                        placeholder={false}
                        width={100}
                        height={100}
                        alt=""
                        image={icon.image}
                      />
                    </div>
                    <p
                      className={cx('text-10 l:text-12 mt-8 flex-wrap', {
                        'text-center':
                          isScreenLarge || (!isScreenLarge && mobileCaptionAlignment === 'center'),
                        'text-left': !isScreenLarge && mobileCaptionAlignment === 'left',
                        'text-right': !isScreenLarge && mobileCaptionAlignment === 'right',
                      })}
                    >
                      {icon.caption}
                    </p>
                  </div>
                )
              })}
          </div>
          <div className="block l:hidden h-full w-24 s:w-40 l:w-60 xl:w-80 absolute top-0 right-0 z-1 bg-gradient-to-r from-transparent-white to-white transition pointer-events-none" />
        </div>
      </div>
    </div>
  )
}

export default CertificationModule
